/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Adjust width for better spacing */
  height: 25px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 25px; /* Rounded background */
  transition: 0.4s;
}

/* The sliding circle */
.slider:before {
  position: absolute;
  content: "☀️"; /* Default to Sun emoji */
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 2px; /* Start position */
  bottom: 2.5px;
  background-color: #fff; /* White background for light mode */
  border: 1px solid #ccc; /* Subtle border for visibility */
  border-radius: 50%;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  color: black; /* Set emoji color to black */
}

/* Change background and emoji on toggle */
input:checked + .slider {
  background-color: #54ca95;
}

input:checked + .slider:before {
  content: "🌙"; /* Moon emoji */
  transform: translateX(24px); /* Move to the right */
  background-color: #fff; /* Same background color for consistency */
  border: 1px solid #54ca95; /* Match the active background color */
}
