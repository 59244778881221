.home-photo .hp-inner {
  background-image: url(./images/profile_photo.jpeg) !important;
}

#wcb #wcb_g {
  border-color: #54ca95 !important;
  color: #aaaaaa !important;
  line-height: 21px !important;
  font-size: 13px !important;
  opacity: .6 !important;
}

#wcb #wcb_a {
  border-color: #54ca95 !important;
  background-color: #54ca95 !important;
  line-height: 21px !important;
  font-size: 13px !important;
  opacity: .6 !important;
}

#wcb #wcb_2 {
  line-height: 21px !important;
  font-size: 13px !important;
  opacity: .6 !important;
}