.logo-symbol {
  margin-right: 2px !important;
}

.menu-item-separator, .menu-item-dark-mode {
  color: #333;
  opacity: 0.55;
}

@media only screen and (max-width: 991px) {
  .menu-item-separator {
    visibility: hidden !important;
  }
}