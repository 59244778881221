.dark-mode {
  .page-scroll, .page-container, .page-title, .site-footer, .timeline-item h5.item-period  {
    color: #FFF !important;
    background-color: #2a2b2e !important;
  }

  h1, h2, h3, h4, .logo-text, .menu-item, .menu-item a {
    color: #FFF !important;
  }

  p, .item-company, .site-footer .footer-social-links li a  {
    color: #9d9d9d !important;
  }

  @media only screen and (max-width: 991px) {
    .header-content {
      border-bottom: 1px solid #eeeeee;
      background-color: #2a2b2e !important;
      color: #9d9d9d !important;
    }

    .site-nav {
      border-left: 1px solid #eeeeee;
      background-color: #2a2b2e !important;
      color: #9d9d9d !important;
    }
  }
}